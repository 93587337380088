import React, { useState, useRef, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import { Camera, Leaf, RefreshCw, Upload, AlertCircle } from 'lucide-react';
import imageCompression from 'browser-image-compression';

const Header = () => (
  <header className="bg-green-600 text-white py-6 shadow-lg">
    <div className="container mx-auto px-4 flex items-center justify-between">
      <div className="flex items-center">
        <Leaf className="mr-3 h-8 w-8" />
        <h1 className="text-3xl font-bold text-white">What's Wrong With My Plants</h1>
      </div>
    </div>
  </header>
);

const Footer = () => (
  <footer className="bg-green-800 text-white py-6 mt-8">
    <div className="container mx-auto px-4 text-center">
      <p>&copy; 2024 What's Wrong With My Plants. All rights reserved.</p>
    </div>
  </footer>
);

const ImageCapture = ({ onCapture }) => {
  const [displayImage, setDisplayImage] = useState(null);
  const [isCameraActive, setIsCameraActive] = useState(false);
  const [cameraError, setCameraError] = useState(null);
  const [stream, setStream] = useState(null);
  const [imageError, setImageError] = useState(null);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const startCamera = async () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      setCameraError("Your browser doesn't support accessing the camera. Please try uploading an image instead.");
      return;
    }

    try {
      // First, let's check what cameras are available
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(device => device.kind === 'videoinput');
      console.log('Available video devices:', videoDevices);

      let constraints;
      if (videoDevices.length > 1) {
        // If there's more than one camera, try to use the rear camera
        constraints = {
          video: {
            facingMode: { exact: "environment" }
          }
        };
      } else {
        // If there's only one camera or we can't determine, use default
        constraints = { video: true };
      }

      console.log('Attempting to access camera with constraints:', constraints);
      const mediaStream = await navigator.mediaDevices.getUserMedia(constraints);
      
      const videoTrack = mediaStream.getVideoTracks()[0];
      console.log('Camera accessed. Settings:', videoTrack.getSettings());

      setStream(mediaStream);
      setIsCameraActive(true);
      setCameraError(null);
    } catch (err) {
      console.error("Error accessing the camera:", err);
      setCameraError(err.message);
      setIsCameraActive(false);

// Fallback to any available camera if rear camera is not accessible
      try {
        console.log("Attempting to access any available camera");
        const fallbackStream = await navigator.mediaDevices.getUserMedia({ video: true });
        setStream(fallbackStream);
        setIsCameraActive(true);
        setCameraError(null);

        const fallbackTrack = fallbackStream.getVideoTracks()[0];
        console.log("Fallback camera accessed. Settings:", fallbackTrack.getSettings());
      } catch (fallbackErr) {
        console.error("Error accessing any camera:", fallbackErr);
        setCameraError("Unable to access any camera. Please try uploading an image instead.");
      }
    }
  };

  const stopCamera = () => {
    if (stream) {
      stream.getTracks().forEach(track => track.stop());
      setStream(null);
    }
    setIsCameraActive(false);
  };

  useEffect(() => {
    if (stream && videoRef.current && !videoRef.current.srcObject) {
      videoRef.current.srcObject = stream;
    }
  }, [stream]);

  const compressImage = async (imageFile) => {
    console.log("Starting image compression");
    const options = {
      maxSizeMB: 0.2,
      maxWidthOrHeight: 600,
      useWebWorker: false // Disable Web Worker to avoid CSP issues
    };

    try {
      const compressedFile = await imageCompression(imageFile, options);
      console.log("Image compressed successfully");
      return compressedFile;
    } catch (error) {
      console.error('Error compressing image:', error);
      setImageError("Failed to compress the image. Using original file.");
      return imageFile;
    }
  };

  const captureImage = async () => {
    if (videoRef.current) {
      try {
        const context = canvasRef.current.getContext('2d');
        context.drawImage(videoRef.current, 0, 0, 640, 480);
        const blob = await new Promise(resolve => canvasRef.current.toBlob(resolve, 'image/jpeg'));
        
        console.log("Image captured from camera");
        setDisplayImage(URL.createObjectURL(blob));
        
        const compressedFile = await compressImage(blob);
        
        const reader = new FileReader();
        reader.onloadend = () => {
          console.log("Sending captured image to backend");
          onCapture(reader.result);
        };
        reader.onerror = (error) => {
          console.error("Error reading compressed file:", error);
          setImageError("Failed to process the captured image. Please try again.");
        };
        reader.readAsDataURL(compressedFile);
        
        stopCamera();
      } catch (error) {
        console.error("Error capturing image:", error);
        setImageError("Failed to capture the image. Please try again.");
      }
    } else {
      setCameraError("Video element not ready. Please try again.");
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        console.log("File selected:", file.name);

        // Create a preview of the original file using blob URL
        const imageUrl = URL.createObjectURL(file);
        setDisplayImage(imageUrl);
        console.log("Preview created");

        // Compress the image for API submission
        const compressedFile = await compressImage(file);
        
        const reader = new FileReader();
        reader.onloadend = () => {
          console.log("Sending uploaded image to backend");
          onCapture(reader.result);
        };
        reader.onerror = (error) => {
          console.error("Error reading uploaded file:", error);
          setImageError("Failed to process the uploaded image. Please try again.");
        };
        reader.readAsDataURL(compressedFile);
      } catch (error) {
        console.error("Error handling file upload:", error);
        setImageError("Failed to process the uploaded image. Please try again.");
      }
    }
  };

  const retakePhoto = () => {
    console.log("Retaking photo");
    if (displayImage) {
      URL.revokeObjectURL(displayImage);
    }
    setDisplayImage(null);
    setImageError(null);
    setCameraError(null);
    startCamera();
  };

  useEffect(() => {
    return () => {
      stopCamera();
      if (displayImage) {
        URL.revokeObjectURL(displayImage);
      }
    };
  }, []);

  return (
    <div className="mb-8 bg-white p-6 rounded-lg shadow-md">
      {!displayImage ? (
        <div className="space-y-6">
          {isCameraActive ? (
            <div className="relative">
              <video 
                ref={videoRef} 
                autoPlay 
                playsInline
                className="w-full rounded-lg shadow-md" 
                onCanPlay={() => videoRef.current.play()}
              />
              <button
                onClick={captureImage}
                className="absolute bottom-4 left-1/2 transform -translate-x-1/2 py-2 px-6 bg-green-600 text-white rounded-full hover:bg-green-700 transition duration-300 flex items-center text-lg"
              >
                <Camera className="mr-2" /> Capture
              </button>
            </div>
          ) : (
            <div className="space-y-6">
              <button
                onClick={startCamera}
                className="w-full py-4 px-6 bg-green-600 text-white rounded-lg hover:bg-green-700 transition duration-300 flex items-center justify-center text-lg"
              >
                <Camera className="mr-2" /> Start Camera
              </button>
              {cameraError && (
                <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 rounded-lg" role="alert">
                  <div className="flex items-center">
                    <AlertCircle className="mr-2" />
                    <strong className="font-bold">Camera Error: </strong>
                  </div>
                  <span className="block sm:inline mt-2">{cameraError}</span>
                  <p className="mt-2">Please ensure you've granted camera permissions and try again. Alternatively, you can upload an image.</p>
                </div>
              )}
              <div className="relative">
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleFileUpload}
                  className="hidden"
                  id="file-upload"
                />
                <label
                  htmlFor="file-upload"
                  className="w-full py-4 px-6 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-300 flex items-center justify-center cursor-pointer text-lg"
                >
                  <Upload className="mr-2" /> Upload Image
                </label>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="relative">
          <img 
            src={displayImage} 
            alt="Captured plant" 
            className="w-full rounded-lg shadow-md" 
            onError={(e) => {
              console.error("Error loading image:", e);
              setImageError("Failed to load the image. Please try again.");
            }}
          />
          <button
            onClick={retakePhoto}
            className="absolute top-4 right-4 py-2 px-4 bg-green-600 text-white rounded-full hover:bg-green-700 transition duration-300 flex items-center text-sm"
          >
            <RefreshCw className="mr-2 h-4 w-4" /> Retake
          </button>
        </div>
      )}
      {imageError && (
        <div className="mt-4 bg-red-100 border-l-4 border-red-500 text-red-700 p-4 rounded-lg" role="alert">
          <p className="font-bold">Error</p>
          <p>{imageError}</p>
        </div>
      )}
      <canvas ref={canvasRef} style={{ display: 'none' }} width={640} height={480} />
    </div>
  );
};

const ImageBanner = () => {
  const bannerImages = [
    { src: '/amazon1.jpg', link: 'https://amzn.to/3VPAyiJ' },
    { src: '/amazon2.jpg', link: 'https://amzn.to/3xWe0op' },
    { src: '/amazon3.jpg', link: 'https://amzn.to/4cqwkFe' },
  ];

  return (
    <div className="mt-8 bg-white p-6 rounded-lg shadow-md">
      <h3 className="text-2xl font-semibold text-green-700 mb-4">Related Products</h3>
      <div className="flex justify-between">
        {bannerImages.map((image, index) => (
          <a
            key={index}
            href={image.link}
            target="_blank"
            rel="noopener noreferrer"
            className="w-1/3 px-2 transition-transform duration-300 hover:scale-105"
          >
            <img
              src={image.src}
              alt={`Related product ${index + 1}`}
              className="w-full h-auto rounded-lg shadow-md"
            />
          </a>
        ))}
      </div>
    </div>
  );
};

const ResultDisplay = ({ analysis, isLoading }) => {
  const parseAnalysis = (text) => {
    const lines = text.split('\n').map(line => line.trim()).filter(line => line);
    let currentSection = 'General Analysis';
    const parsedSections = { [currentSection]: [] };

    lines.forEach(line => {
      if (line.startsWith('###')) {
        currentSection = line.replace('###', '').trim();
        parsedSections[currentSection] = [];
      } else if (/^\d+\./.test(line)) {
        // This is a numbered list item
        parsedSections[currentSection].push(line);
      } else {
        // This is regular text
        parsedSections[currentSection].push(line);
      }
    });

    return parsedSections;
  };

  const formatText = (text) => {
    const parts = text.split(/(\*\*.*?\*\*)/);
    return parts.map((part, index) => {
      if (part.startsWith('**') && part.endsWith('**')) {
        return <strong key={index}>{part.slice(2, -2)}</strong>;
      }
      return part;
    });
  };

  const renderSection = (title, content) => (
    <div key={title} className="mb-6 bg-white p-6 rounded-lg shadow-md">
      <h3 className="text-2xl font-semibold text-green-700 mb-4">{title}</h3>
      {content.map((item, index) => {
        if (/^\d+\./.test(item)) {
          return <p key={index} className="ml-6 mb-3 text-gray-700">{formatText(item)}</p>;
        } else {
          return <p key={index} className="mb-3 text-gray-700">{formatText(item)}</p>;
        }
      })}
    </div>
  );

  if (isLoading) {
    return (
      <div className="mt-8 bg-white p-8 rounded-lg shadow-md flex justify-center items-center">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-green-600"></div>
      </div>
    );
  }

  if (!analysis) {
    return (
      <div className="mt-8 bg-white p-8 rounded-lg shadow-md text-center">
        <p className="text-gray-600 italic text-lg">Capture or upload an image to see the analysis.</p>
      </div>
    );
  }

  const parsedAnalysis = parseAnalysis(analysis);

  return (
    <div className="mt-8">
      <h2 className="text-3xl font-bold text-green-800 mb-6">Plant Analysis Result</h2>
      {Object.entries(parsedAnalysis).map(([title, content]) => renderSection(title, content))}
      <ImageBanner />
    </div>
  );
};

const App = () => {
  const [analysis, setAnalysis] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleImageCapture = async (imageDataUrl) => {
    console.log("handleImageCapture called");
    setIsLoading(true);
    setError(null);
    try {
      console.log('Sending request to API Gateway...');
      const response = await fetch('https://il7kf24xdb.execute-api.us-east-1.amazonaws.com/prod/whatswrongwithmyplant', {
        method: 'POST',
        mode: 'cors',
        headers: { 
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ image: imageDataUrl }),
      });
      console.log('Received response:', response);
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Error response:', errorText);
        throw new Error(`HTTP error! status: ${response.status}, body: ${errorText}`);
      }
      const data = await response.json();
      if (data.error) {
        throw new Error(data.error);
      }
      console.log('Analysis received:', data.analysis);
      setAnalysis(data.analysis);
    } catch (error) {
      console.error('Error analyzing plant:', error);
      setError(`Error: ${error.message}`);
      setAnalysis(null);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-green-50">
      <Header />
      <main className="flex-grow container mx-auto px-4 py-8">
        <div className="max-w-3xl mx-auto">
          <h1 className="text-4xl font-bold text-center text-green-800 mb-8">Plant Health Analyzer</h1>
          <ImageCapture onCapture={handleImageCapture} />
          {error && (
            <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 rounded-lg mb-8" role="alert">
              <div className="flex items-center">
                <AlertCircle className="mr-2" />
                <strong className="font-bold">Error: </strong>
              </div>
              <span className="block sm:inline mt-2">{error}</span>
            </div>
          )}
          <ResultDisplay analysis={analysis} isLoading={isLoading} />
        </div>
      </main>
      <Footer />
    </div>
  );
};

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);